<template>
    <!-- 表单管理分类管理 -->
    <div class="formment-box">
        <div class="box">

            <div class="title">
                <div class="t-label">
                    <label @click="goClass">产品分类</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png" />
                <div class="t-label current">
                    <label>{{ type == 1 ? "添加分类" : "编辑分类" }}</label>
                </div>
            </div>

            <div class="setbox">
                <div class="inputbox">
                    <el-form ref="form" :model="form" label-width="150px" :rules="rules">
                        <el-form-item label="分类名称" prop="className">
                            <el-input v-model="form.className" placeholder="请输入分类名称"></el-input>
                        </el-form-item>
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="form.title" placeholder="请输入排序"></el-input>
                        </el-form-item>
                        <el-form-item label="自定义分享图片" prop="imgUrl">
                            <addImg @upimgSuccess="upimgSuccess" :imgUrl="form.imgUrl" imgWidth="100px" imgHeight="100px" tips="建议尺寸200*200"></addImg>
                        </el-form-item>
                        <el-form-item label="是否启用">
                            <el-switch v-model="form.is_status" :active-value="1" :inactive-value="0"></el-switch>
                        </el-form-item>
                        <el-form-item label="是否显示">
                            <el-switch v-model="form.is_display" :active-value="1" :inactive-value="0"></el-switch>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <div class="btn-box">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="btnclick" v-if="type == 1">添加</el-button>
                <el-button type="primary" @click="editClick" v-else-if="type == 2">编辑</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import rules from "@/utils/rules";
import addImg from "@/components/addIimage/addImg.vue";
import { sessionGetKey } from "@/utils/sessionStorage.js";
import deepCopy from '@/utils/deepCopy'
export default {
    components: {
        addImg,
    },
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        if (this.$route.query.data) {
            const data = JSON.parse(this.$route.query.data)
            console.log(data,'编辑');
            this.form.id = data.id
            this.form.className = data.name
            this.form.title = data.title
            this.form.imgUrl = data.pic
            this.form.is_status = data.is_status
            this.form.is_display = data.is_display
            this.storageForm = deepCopy(this.form)
        }
    },
    data() {
        return {
            type: "1",
            platform_id: "",
            rules: rules,
            form: {
                id:'',
                className: "",
                title: "",
                imgUrl: "",
                is_status: 0,
                is_display: 0,
            },
            storageForm:{}
        };
    },
    methods: {
        goClass(){
          this.$router.push('/productMent/class')
        },
        btnclick() {
            const that = this;
            let { className, title, imgUrl, is_status, is_display } = that.form;
            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.goodClass.addClass,
                        method: "post",
                        data: {
                            name: className,
                            title,
                            pic: imgUrl,
                            is_status,
                            is_display,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/productMent/class");
                    });
                }
            });
        },
        upimgSuccess(imgUrl) {
            this.form.imgUrl = imgUrl;
        },
        reset() {
            if(this.type == 1){
              this.form = {
                  id:'',
                  className: "",
                  title: "",
                  imgUrl: "",
                  is_status: 0,
                  is_display: 0,
              }
            }else if(this.type == 2){
              this.form = deepCopy(this.storageForm)
            }
        },
        editClick() {
            const that = this;
            let { id,className, title, imgUrl, is_status, is_display } = that.form;
            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.goodClass.editClass,
                        method: "post",
                        data: {
                            id,
                            name: className,
                            title,
                            pic: imgUrl,
                            is_status,
                            is_display,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/productMent/class");
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    padding: 33px;
    background: #fff;
    margin-top: 30px;
}
.editor {
    margin: 20px;
    /deep/.ql-editor {
        min-height: 200px !important;
    }
}
.formment-box {
    // height: 100%;
    padding: 66px;
    //   min-height: 600px;
    .upload-demo {
        /deep/.el-upload-list {
            display: flex;
            .el-upload-list__item {
                overflow: hidden;
                width: 100px;
                height: 100px;
                margin-right: 10px;
                padding: 0px 10px 10px 80px;
                .el-upload-list__item-thumbnail {
                    width: 100px;
                    height: 100px;
                }
                .el-upload-list__item-status-label {
                    z-index: 999;
                }
                .el-icon-close {
                    z-index: 999;
                }
            }
        }
    }
    .upload-box {
        .tip {
            font-size: 14px;
            color: #999;
            margin-left: 15px;
        }
        /deep/.el-button {
            width: 100px;
            background: #fff;
            color: #999;
            border-color: #999;
        }
    }

    .setbox {
        margin-top: 20px;
        color: #333333;

        .title {
            background: #edefff;
            height: 35px;
            line-height: 35px;
            padding-left: 15px;
        }
        /deep/.el-input__inner {
            width: 420px;
        }
        /deep/.input-id {
            margin-left: 20px;
            width: 100px;
        }
        /deep/.input-id .el-input__inner {
            width: 100px;
        }
        /deep/.el-form-item__content {
            // display: flex;
            // align-items: center;
        }
        .inputbox {
            //   margin-left: 15%;

            margin-top: 24px;
        }
    }

    .box {
        // overflow: hidden;
        // position: relative;
        border-radius: 16px;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 33px;
        .top-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /deep/.el-breadcrumb__item {
                padding-bottom: 10px;

                font-size: 20px !important;
                //
            }
            /deep/.active {
                color: #4458fe !important;
                border-bottom: #4458fe 2px solid;
                .el-breadcrumb__inner {
                    color: #4458fe !important;
                }
            }
        }
    }
}

.title {
    .t-label {
        display: inline-block;
        height: 35px;

        label {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
        }
    }

    img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .current {
        border-bottom: 2px solid #4458fe;
        label {
            color: #4458fe;
        }
    }
}
</style>
