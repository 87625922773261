<!--
    单张图片上传
-->

<template>
    <div>
        <!--  :limit="limit" -->
        <el-upload
            class="upload-demo"
            :action="url"
            
            :file-list="fileList"
            :show-file-list="false"
            list-type="picture"
           
            accept="image/*"
            :on-success="hangelSuccess"
            :before-upload="hangelUpload"
        >
            <el-button id="upload" size="small" type="primary">选择图片</el-button>
            <span class="tips">{{tips}}</span>
        </el-upload>
        <div class="avatar" v-if="imageUrl" :style="{'--imgWidth':imgWidth,'--imgHeight':imgHeight}">
            <img class="image-url" :src="imageUrl"/>
            <img class="del-icon" src="@/assets/images/icon/upimg-del.png" @click="removeImg">
        </div>
        <div class="default-icon level-vertical-center" v-else >
            <img src="@/assets/images/icon/upimg-default.png">
        </div>

        <!--
            预览图片
             <el-dialog width="20%" center :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" />
        </el-dialog> -->
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
    props: {
        imgUrl:{
            type:String,
            default:''
        },
        imgWidth: {
            type:String,
            default:'100px'
        },
        imgHeight:{
            type:String,
            default:'100px'
        },
        tips:{
            type:String,
            default:''
        }
    },
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        this.url = `https://tw.huayeyun.cn/api/Admin/upload?platform_id=${this.platform_id}`;
    },
    watch:{
        imgUrl(newVal,oldVal){
            this.imageUrl = newVal
        }
    },
    data() {
        return {
            fileList: [],
            imageUrl:"",
            url: "",
            platform_id: "",
            dialogVisible:false
        };
    },
    methods: {
        hangelSuccess(res,file,fileList){
            // URL.createObjectURL(file.raw)
            this.imageUrl = res.data
            this.$emit('upimgSuccess',this.imageUrl)
        },
        hangelUpload(){
            //可以写一些限制图片大小和格式
        },
        removeImg(){
            this.imageUrl = ''
            this.$emit('upimgSuccess',this.imageUrl)
        }
    },
};
</script>

<style lang="scss" scoped>
.upload-demo {
    /deep/ .el-upload-list {
        display: flex;
        .el-upload-list__item {
            overflow: hidden;
            width: 100px;
            height: 100px;
            margin-right: 10px;
            padding: 0px 10px 10px 80px;
            .el-upload-list__item-thumbnail {
                width: 100px;
                height: 100px;
            }
            .el-upload-list__item-status-label {
                z-index: 999;
            }
            .el-icon-close {
                z-index: 999;
            }
        }
    }
}

.avatar {
    min-width: 100px;
    max-width: var(--imgWidth);
    height: var(--imgHeight);
    padding: 5px;
    border: 1px solid #999999;
    border-radius: 6px;
    position: relative;
    .image-url{
        width: 100%;
        height: 100%;
    }

    .del-icon{
        position: absolute;
        top:-5px;
        right: -10px;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
}

.default-icon{
    width: 100px;
    height: 100px;
    border: 1px solid #999999;
    border-radius: 6px;
    img{
        width: 48px;
        height: 48px;
    }
}


.tips{
    color: #999999;
    margin-left: 20px;
}
</style>
