const rules = {
    name: [{
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
        },
        // {
        //     pattern: /^[a-z0-9]{5,10}$/,
        //     message: '用户名必须是5～10个字母或者数字',
        //     trigger: 'blur'
        // }
    ],
    password: [{
            required: true,
            message: '密码不能为空',
            // ['blur', 'change']
            trigger: 'blur'
        },
        {
            // pattern: /^[a-z0-9]{3,}$/,
            min: 3,
            max: 11,
            message: '密码必须是3位到11位',
            trigger: 'change'
        }
    ],
    conPws:[{
        required: true,
            message: '密码不能为空',
            trigger: 'blur'
    }],
    mallName: [{
        required: true,
        message: '商城名称不能为空',
        trigger: 'blur'
    }],
    mallValid: [{
        required: true,
        message: '有效期不能为空',
        trigger: 'blur'
    }],
    mallRemarks: [{
        max: 15,
        message: '不可超过15位',
        trigger: 'change'
    }],
    marName: [{
        required: true,
        message: '用户名不能为空',
        trigger: 'blur'
    }],
    psw: [{
        required: true,
        message: '密码不能为空',
        trigger: 'blur'
    }],
    phone: [{
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
        },
        {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: '手机号码格式不正确',
            trigger: 'blur'
        }
    ],
    account: [{
        required: true,
        message: '账号不能为空',
        trigger: 'blur'
    }],
    app_num: [{
        pattern: /^[0-9]\d*$/,
        message: '请输入正整数',
        trigger: 'change'
    }],
    sort: [{
            required: true,
            message: '排序不能为空',
            trigger: 'blur'
        },
        {
            pattern: /^[0-9]{1,3}$/,
            message: '请输入正整数,最大3位数',
            trigger: 'change'
        }
    ],
    link: [{
        required: true,
        message: '链接不能为空',
        trigger: 'blur'
    }, ],
    productId: [{
        required: true,
        message: '产品ID不能为空',
        trigger: 'blur'
    }],
    category_id: [{
        required: true,
        message: '产品分类不能为空',
        trigger: 'blur'
    }],
    productName: [{
        required: true,
        message: '产品名称不能为空',
        trigger: 'blur'
    }],
    form_id: [{
        required: true,
        message: '表单ID不能为空',
        trigger: 'blur'
    }],
    tempContent: [{
        required: true,
        message: '参数内容不能为空',
        trigger: 'blur'
    }],
    tempName: [{
        required: true,
        message: '参数内容不能为空',
        trigger: 'blur'
    }],
    className: [{
        required: true,
        message: '分类名称不能为空',
        trigger: 'blur'
    }],
    title: [{
        required: true,
        message: '标题不能为空',
        trigger: 'blur'
    }],
    imgUrl: [{
        required: true,
        message: '图片不能为空',
        trigger: 'blur'
    }],
    title: [{
        required: true,
        message: '标题不能为空',
        trigger: 'blur'
    }],
    noticeTime:[{
        required: true,
        message: '时间不能为空',
        trigger: 'blur'
    }],
    noticeContent:[{
        required: true,
        message: '内容不能为空',
        trigger: 'blur'
    }],
    userName:[{
        required: true,
        message: '用户名不能为空',
        trigger: 'blur'
    }],
    auth:[{
        required: true,
        message: '权限不能为空',
        trigger: 'blur'
    }],
    roleName:[{
        required: true,
        message: '角色名称不能为空',
        trigger: 'blur'
    }],
    roleAuth:[{
        required: true,
        message: '角色权限不能为空',
        trigger: 'blur'
    }],
    actorId:[{
        required: true,
        message: '角色不能为空',
        trigger: 'blur'
    }]
}

export default rules